import React from "react"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import MuiLink from "@material-ui/core/Link"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import CallOutlinedIcon from "@material-ui/icons/CallOutlined"

import Layout from "../components/layout"
import Head from "../components/head"
import heroContactSvg from "../assets/hero-contact.svg"

const HeroContent = styled.div`
  padding: ${props => props.theme.spacing(7, 0, 4)};

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    padding: ${props => props.theme.spacing(10, 0, 7)};
  }

  .hero-grid-container {
    display: grid;
    grid-gap: ${props => props.theme.spacing(6)}px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;

    .bottom-margin {
      margin-bottom: ${props => props.theme.spacing(6)}px;

      .contact-detail {
        margin: ${props => props.theme.spacing(7, 0)};

        .icon-wrap {
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .hero-grid-item-image {
    text-align: center;

    .hero-image {
      width: 100%;
    }
  }
`

const Contact = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <HideOnScrollMargin>
        <HeroContent>
          <Container maxWidth="lg">
            <div className="hero-grid-container">
              <div>
                <div className="bottom-margin">
                  <div
                    data-sal="slide-right"
                    data-sal-delay="100"
                    data-sal-duration="800"
                    data-sal-easing="ease-out-cubic"
                  >
                    <Typography
                      component="h1"
                      variant="h2"
                      align="left"
                      color="textPrimary"
                      gutterBottom
                    >
                      Let's talk
                    </Typography>
                    <Typography
                      variant="h5"
                      align="left"
                      color="textSecondary"
                      paragraph
                    >
                      To discuss how we could partner on an upcoming project or
                      boost your brand long-term, get in touch.
                    </Typography>
                  </div>

                  <div
                    data-sal="slide-right"
                    data-sal-delay="300"
                    data-sal-duration="800"
                    data-sal-easing="ease-out-cubic"
                  >
                    <div className="contact-detail">
                      <Grid container spacing={2} justify="flex-start">
                        <Grid item>
                          <div className="icon-wrap">
                            <MailOutlineIcon />
                          </div>
                        </Grid>
                        <Grid item>
                          <MuiLink
                            href="mailto:mylan@vu-consulting.com"
                            variant="h6"
                          >
                            mylan@vu-consulting.com
                          </MuiLink>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} justify="flex-start">
                        <Grid item>
                          <div className="icon-wrap">
                            <CallOutlinedIcon />
                          </div>
                        </Grid>
                        <Grid item>
                          <MuiLink href="tel: 0466397541" variant="h6">
                            0466 397 541
                          </MuiLink>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero-grid-item-image ">
                <div
                  data-sal="zoom-in"
                  data-sal-delay="100"
                  data-sal-duration="800"
                  data-sal-easing="ease-out-cubic"
                >
                  <img
                    src={heroContactSvg}
                    alt="Contact us"
                    className="hero-image"
                  />
                </div>
              </div>
            </div>
          </Container>
        </HeroContent>
      </HideOnScrollMargin>
    </Layout>
  )
}

const HideOnScrollMargin = styled.main`
  margin-top: 56px;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    margin-top: 64px;
  }
`

export default Contact
